body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 16px;
  min-height: 100vh;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: hsl(0, 0%, 35%);
}

html {
  overflow-y: auto;
}
